import { useEffect, useState } from "react";
import ApartCard from "../apart-card";
import * as Constants from "../../config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ApartsBlock(){

    const { t } = useTranslation();
    const [apartments, setApartments] = useState();
    
    const navigate = useNavigate();

    function getData() {
        fetch(Constants.apiurl + Constants.apartments)
        .then((response) => response.json())
        .then((data) => {
            setApartments(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }
    useEffect(() => {
        getData();
     }, []);

    return(
        <div className="padding-block-content">
            <div id="title">
                <div>TROUVEZ VOTRE BIEN IDÉAL </div>
            </div>
            <div id="aparts-cards">
                {!!(apartments) ? apartments.filter(x=>x.displayOnMain == true).map(x=>
                    <ApartCard data={x}/>
                ) : ""}
            </div>            
            <div id="view-more-button" onClick={()=>navigate("apartments-search")} className="blue-button">{t('Voir plus')}</div>
        </div>
    )
}