import SocialLinks from "./social-links";
import * as Query from "../static data/queryparams";
import { useLocation, useNavigate } from "react-router-dom";
import {menuitems} from "../static data/menu-items"
import { useState } from "react";
import ContactUs from "./contact-us";

export default function Footer(){
    const location = useLocation();
    const [modalOpened,setModalOpened] = useState(false);
    const navigate = useNavigate();  
    function goToPage(link){
        // navigate("/apartments-search?"+Query.property + "=" + link);
         navigate({
             pathname: "/apartments-search",
             search: Query.property + "=" + link
         });
         if(location.pathname=="/apartments-search")navigate(0);
     }   

     function CloseModal(){
        setModalOpened(false);
     }

     function goToServices(){       
        setModalOpened(true);
        return;
        navigate({
            pathname: "/main",
            hash: "#services"
        });
        if(location.pathname=="/main")navigate(0);
    }    
    return(
        <>
            {modalOpened ? <ContactUs closeModal = {CloseModal}/> : ""}
            <div className="footer">
                <div id="block">
                    <div id="title">
                        À PROPOS DE NOUS
                    </div>
                    <div id="contacts" className="middle-size-text">
                    L’agence immobilière LM Platinum Estates est une entreprise camerounaise immobilière qui a un engagement sérieux avec la satisfaction du client au premier plan. À travers une équipe d’agents expérimentés et un travail acharné, nous proposons de nombreux services à l’intention des vendeurs, des investisseurs et des locataires. Active sur le marché de l’immobilier au Cameroun, nous préconisons fortement l’innovation, l’honnêteté et la transparence. En effet, compte tenu des challenges et des défis sur le marché de l’immobilier de nos jours et surtout au Cameroun, nous visons l’excellence.
                    </div>
                </div>
                <div id="block">
                    <div id="title">
                        Acheter
                    </div>
                    <div id="contacts">
                    {menuitems[1].submenu.map(x=>
                                        <>
                                            <div className="link-item" onClick={()=>goToPage(x.link)}>
                                                {x.name}
                                            </div>
                                        </>
                                    )}
                    </div>
                </div>
                <div id="block">
                    <div id="title">
                        Louer
                    </div>
                    <div id="contacts">
                    {menuitems[2].submenu.map(x=>
                                        <>
                                            <div className="link-item" onClick={()=>goToPage(x.link)}>
                                                {x.name}
                                            </div>
                                        </>
                                    )}
                    </div>
                </div>
                <div id="block">
                    <div id="title">
                        Nos Services
                    </div>
                    <div id="contacts" >
                        <div onClick={goToServices}  className="clickable"> 
                        TOPOGRAPHIE
                        </div>
                        <div onClick={goToServices}  className="clickable"> 
                        CONSTRUCTRUIRE
                        </div>
                    </div>
                </div>
                <div id="block">
                    <div id="title">
                        Contacter nous
                    </div>
                    <div id="contacts">
                        <div>
                        Email : <a href="mailto:admin@lmplatinumestates.cm">admin@lmplatinumestates.cm</a><br/>
                        </div>
                        <div> 
                            Calls/WhatsApp : <a href="tel:+237 692 85 05 84">+237 692 85 05 84</a><br/>
                        </div>
                        <div> 
                        Calls : <a href="tel:+237 651 12 50 90">+237 651 12 50 90</a> / <a href="tel:+237 658 91 74 77">+237 658 91 74 77</a><br/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="social-footer-icons">
                    <SocialLinks/>
            </div>
        </>
    )
}