import { useEffect, useState } from "react";
import uuidv4 from "../tools/guidgenerator";
import { useTranslation } from 'react-i18next';
import * as Constants from "../config";
import * as selectvalues from "../static data/selectvalues";
import * as Query from "../static data/queryparams";
import {checkArrayDefined, numberWithCommas} from "../tools/valueshelper";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

export default function ApartCard(props){

    const { t } = useTranslation();
    const [imagesState,setImagesState] = useState();
    const [loaded,setLoaded] = useState(false);
    const location = useLocation();
    const [apartment, setApartment] = useState(props.data);

    const navigate = useNavigate();

    var apart = {            
        id:uuidv4(),
        name:"",
        price:"",
        landarea:"",
        livingarea:"",
        propertytype:"",
        typeofoffer:"",
        neighborhood:"",
        bedrooms:"",
        showers:"",
        properties:
        [
            {id:0, name:"",options:[]}
        ]
    };

    function getImages() {
        fetch(Constants.apiurl + Constants.imagesListPath + "?id=" + apartment.id
            
        )
        .then((response) => response.json())
        .then((data) => {
            setImagesState(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }

    function openDetails(){
        let search = window.location.search;
        let params = new URLSearchParams(search);
        params.delete(Query.id);
        params.append(Query.id, apartment.id);
        navigate({
            pathname: "/apart",
            search: createSearchParams(params).toString()
        });
        if(location.pathname=="/apart")navigate(0);
    }

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 4000);
        getImages();
     }, []);


    return(
        <div id="apart-card" onClick={openDetails}>
            <div id="apart-image">
                {!loaded ?
                    <div className="loader-box">
                        <div className="loader"></div> 
                    </div>
                    :""
                    }  
                <img style={{display: loaded? 'block': 'none'}}
                onLoad={()=>setLoaded(true)}
                src={checkArrayDefined(apartment.images) ? apartment.images[0].link : apartment.propertytype =='Terrain' ? "terrain_default.jpg" : ""}/>
                {apartment.status == selectvalues.Promotiontatus && checkArrayDefined(apartment.promotionprice) ?
                    <div className="promotion-block">
                       {numberWithCommas( apartment.promotionprice)} FCFA {apartment.propertytype =='Terrain' ? 'par m²' :"" }
                    </div>
                    :""
                }
            </div>
            <div id="price" className={apartment.status == selectvalues.Promotiontatus ? "strikethrough-price" : ""}>                
                {numberWithCommas( apartment.price)} FCFA {apartment.propertytype =='Terrain' ? 'par m²' :"" } 
            </div>
            <div id="neighborhood">                
                {apartment.neighborhood}
            </div>
            <div id="name">                
                {apartment.name}
            </div>
            <div className="line">
            </div>
            <div id="icons">
                {apartment.propertytype !='Terrain' ?
                <>
                    <div>
                        <img src="bed.svg"/>                                    
                        {apartment.bedrooms} {t('Chambres')}
                    </div>
                    <div>
                        <img src="bath.svg"/>                                    
                        {apartment.showers} {t('Douches')}
                    </div>
                </>
                :""
                }
                <div>
                    <img src="area.svg"/>                                    
                    {apartment.landarea} {t('m²')}
                </div>
            </div>
        </div>
    )
}