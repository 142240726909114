import { useEffect, useState } from "react";
import AddApartment from "./add-apartment";
import uuidv4 from "../../tools/guidgenerator";
import * as Constants from "../../config";
import * as Vaules from "../../static data/selectvalues";
import { checkArrayDefined } from "../../tools/valueshelper";

export default function Apartment(){

    const[apartmentModalOpened,setApartmentModalOpened] = useState(false);

    const[selectedApart, setSelectedApart] = useState({});
    const[searchId, setsearchId] = useState("");
    const[checkboxDisabled, setCheckboxDisabled] = useState(false);
    const[searchName, setSearchName] = useState("");
    const[searchApartments, setSarchApartments] = useState([]);

    var apart = {            
        id:uuidv4(),
        name:"",
        price:"",
        landarea:"",
        livingarea:"",
        propertytype:Vaules.propertyValues[0],
        typeofoffer:Vaules.offerValues[0],
        neighborhood:"",
        bedrooms:Vaules.chambersValues[0],
        showers:Vaules.bathValues[0],
        properties:
        [
            {id:0, name:"",options:[]}
        ],
        images:[]
    };

    const aparts = [
        {            
        id:uuidv4(),
        name:"",
        price:"",
        landarea:"",
        livingarea:"",
        propertytype:"",
        typeofoffer:"",
        neighborhood:"",
        displayOnMain:"",
        bedrooms:"",
        showers:"",
        order:99,
        properties:
        [
            {id:0, name:"",options:[]}
        ]
    },
];
    const [apartments, setApartments] = useState([]);

    function edit(a){
        setSelectedApart(a);
        setApartmentModalOpened(true);
    }

    function add(){        
        apart.id=uuidv4();
        setSelectedApart(apart);
        setApartmentModalOpened(true);
    }

    const saveData = (data) => {
        var d = {};
        Object.assign(d, data);
        d.price = d.price.replaceAll(",","").replaceAll(".","");
        const body = Constants.requestBody(d);
        fetch(Constants.apiurl + Constants.apartments, body)
        .then(response => {
            if(response.status == 401) window.location.reload(false);
            return response.json();
        })
        .then(data => {
            getData();
        })
        .catch(err => {
            console.log(err.error);
        });
    }

    function editDone(a){
        var newObj=[];
        Object.assign(newObj, apartments);
        const i = newObj.findIndex(x=> x.id == a.id);
        if(i<0){
            newObj.push(a);
        }
        else
            newObj[i]=a;
        setApartments(newObj);
        saveData(a);
        closeModal();
    }

    function getData() {
        fetch(Constants.apiurl + Constants.apartments)
        .then((response) => response.json())
        .then((data) => {
            setApartments(data);
            setSarchApartments(data);
        })
        .catch((err) => {
           console.log(err.message);
        });
    }
    useEffect(() => {
        getData();
     }, []);

    useEffect(() => {
        search();
     }, [searchId,searchName]);

    function closeModal(){
        setApartmentModalOpened(false);
    }

    const delet = (x) => {
        fetch(Constants.apiurl + Constants.deleteapartments + "?id=" + x)
        .then((data) => {
            getData();
        })
        .catch((err) => {            
            console.log(err.message);
        });
    };

    function checkboxClick(id){
        var newObj=[];
        Object.assign(newObj, apartments);
        const i = newObj.findIndex(x=> x.id == id);
        newObj[i].displayOnMain = !(newObj[i].displayOnMain);
        setApartments(newObj);
        saveData(newObj[i]);
        if(newObj.filter(x=>x.displayOnMain).length >= 6)
            setCheckboxDisabled(true);
        else
            setCheckboxDisabled(false);
    }

    function search(){
        var s = [];
        Object.assign(s,apartments);
        if(searchId != "" && searchId != null)
            s = s.filter(x=>x.id.includes(searchId));
        if(searchName != "" && searchName != null)
            s = s.filter(x=>x.name.toLowerCase().includes(searchName.toLowerCase()));
        setSarchApartments(s);
    }

    function changeid(e){
        const { name, value } = e.target;
        setsearchId(value);
    }
    function changename(e){
        const { name, value } = e.target;
        setSearchName(value);
    }


    return(
        <div>
        {apartmentModalOpened ?
        <div className="modal-window">
            <div>
                <div className="close-icon" onClick={closeModal}>X</div>
                 <AddApartment closeWindow={closeModal} saveApart={editDone} apart={selectedApart}/>
            </div>
        </div>
        : ""}

        <div onClick={add} className="add-button">Ajouter</div>  
        <table className="apartment-table">
            <thead>
                <tr>
                    <th>
                        Afficher sur la page
                    </th>
                    <th>
                        ID 
                        <input name="id" value={searchId} onChange={changeid}/>
                    </th>
                    <th>
                        status 
                    </th>
                    <th>
                        Nom  
                        <input name="id" value={searchName} onChange={changename}/>
                    </th>
                    <th>
                        Prix
                    </th>
                    <th>
                        Location
                    </th>
                    <th>
                        Superficie terrain
                    </th>
                    <th>
                        Superficie habitable
                    </th>
                    <th style={{width:"220px"}}>
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                {!!(searchApartments) ? searchApartments.map(x=>
                    <tr>
                        <td>
                            <input disabled={checkboxDisabled && !x.displayOnMain} type="checkbox" checked={x.displayOnMain} onChange={()=>checkboxClick(x.id)}/>
                        </td>
                        <td>{x.id}</td>
                        <td>{x.status}</td>
                        <td>{x.name}</td>
                        <td>{x.price}</td>
                        <td>{x.neighborhood}</td>
                        <td>{x.landarea}</td>
                        <td>{x.livingarea}</td>
                        <td>
                            <div onClick={()=>edit(x)} className="edit-button">Editer</div>
                            <div onClick={()=>delet(x.id)} className="delete-button">Supprimer</div>
                        </td>
                    </tr>
                ) : ""}
            </tbody>
        </table>     
        </div>
    )
}
