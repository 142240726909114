import { BrowserView, MobileView, isMobile } from "react-device-detect";

export default function OurServices(){
    const values=[
        {title:"ACHETER",
        text:
        "Acheter des biens immobiliers chez LM Platinum Estates c’est choisir des biens titrés et vérifiés auprès des autorités compétentes. Achetez vos terrains, maisons, immeubles et autres biens immobiliers en un seul clic. Nos offres sont disponibles à Yaoundé, Douala et Kribi principalement mais aussi dans d’autres villes du Cameroun."
        +"Consultez notre catalogue de terrains et maisons à vendre."
        },
        {
            title:"LOUER",
text:"Nous mettons à votre disposition des biens de qualité à des prix compétitifs. Nos conditions de travail sont claires, des contrats de location transparents pour éviter des abus en tout genre."
+"Vous recherchez une maison à louer, un appartement à louer, une boutique ou autres biens à louer. "
+"Consultez notre catalogue de biens immobiliers à louer."
    },
        {title:"CONSTRUCTION ",
        text:"Notre équipe d’expert en BTP se tient à vos dispositions pour la réalisation de vos projets de construction clés en main.\n\r "
        +"Notre objectif des biens solides et agréable à vivre.\n\r"
        +" Nos compétences :\n\r"
        +    "  - Programmation et étude de faisabilité\n\r"
        +    "  - Élaboration des plans et conception schématique\n\r"
        +    "  - Documents contractuels\n\r"
        +    "  - Construction"
},
        {title:"GESTION LOCATIVE ",
        text:"Bénéficiez d’une équipe d’agent immobiliers expérimentés constituer de propriétaires qui comprennent vos attentes et vos besoins.\n\r "
        +"Nous vous accompagnons de la recherche du locataire et la gestion complète de votre bien. Nous vous déchargeons de tout !\n\r"
        +" Notre offre de gestion locative comprend:\n\r"
        +    "   - La recherche du locataire idéal\n\r"
        +    "   - Gestion administrative\n\r"
        +    "  - Rédaction des contrats et gestion des contrats\n\r"
        +    "   - Communication avec les locataires\n\r"
        +    "   - Entretien et réparation\n\r"
        +    "   - Rapports financiers\n\r"
        +    "Une gestion rigoureuse et transparente, un suivi sans faille. Votre bien est entretenu au fil du temps avec des locataires responsables. Un revenu locatif perçu dans les temps."
},
        {title:"VENDRE OU LOUER SON BIEN IMMOBILIER ",
        text:"« Vous souhaitez vendre ou louer votre bien rapidement et au meilleur prix ? »\n\r "
        +" Nous mettons à votre disposition :\n\r"
        +    "   -  Une estimation gratuite de votre bien\n\r"
        +    "   -  Nettoyage et embellissement du bien\n\r"
        +    "  - Photos et vidéos de qualité de votre bien\n\r"
        +    "   -  Suivi et compte rendu\n\r"
        +    "   -  Délai de vente court\n\r"
        +    "   -  Rédaction des contrats de vente\n\r"
        +    "Vous avez le bien et nous avons le client."
},
        {title:"TOPOGRAPHIE ",
        text:""
        +    "  - Bornage \n\r"
        +    "  - Terrassement\n\r"
        +    "  - Conception et plan topographique\n\r"
        +    "  - Urbanisme\n\r"
        +    "  - Tirage et plan topo\n\r"
        +    "  - Location de matériel topo\n\r"
        +    "  - Immatriculation\n\r"
        +    "Vous avez les terres mais pas les moyens de le faire immatriculer LM Platinum Estates se porte garant de tout. Nous finançons votre projet de A à Z.\n\r"
        +    "  - Lotissement \n\r"
        +    "  - Morcellement"
}];

    return <>
        <div id="our-services">
            <div>
                <div id="title">
                    {values[0].title}
                </div>
                <div id="text">                    
                    {values[0].text}
                </div>
                <div id="image">
                    <img src="5379637510122105177.jpg"/>
                </div>
            </div>
            {!isMobile ? 
            <div>                
                <div id="image">
                    <img src="5379637510122105178.jpg"/>
                </div>
                <div id="title">
                    {values[1].title}
                </div>
                <div id="text">                    
                    {values[1].text}
                </div>
            </div>
            :
            <div>       
                <div id="title">
                    {values[1].title}
                </div>
                <div id="text">        
                    {values[1].text}   
                </div>         
                <div id="image">
                    <img src="5379637510122105178.jpg"/>
                </div>
            </div>
            }
            <div>        
                <div id="title">
                    {values[2].title}
                </div>
                <div id="text">                    
                    {values[2].text}
                </div>        
                <div id="image">
                    <img src="5379637510122105174.jpg"/>
                </div>
            </div>
        </div>
        <div id="our-services">
            <div>
                <div id="title">
                    {values[3].title}
                </div>
                <div id="text">                    
                    {values[3].text}
                </div>
                <div id="image">
                    <img src="5379637510122105176.jpg"/>
                </div>
            </div>
            {!isMobile ? 
            <div>                
                <div id="image">
                    <img src="terrain_default.jpg"/>
                </div>
                <div id="title">
                    {values[4].title}
                </div>
                <div id="text">                    
                    {values[4].text}
                </div>
            </div>
            :
            <div>       
                <div id="title">
                    {values[4].title}
                </div>
                <div id="text">        
                    {values[4].text}   
                </div>         
                <div id="image">
                    <img src="terrain_default.jpg"/>
                </div>
            </div>
            }
            <div>        
                <div id="title">
                    {values[5].title}
                </div>
                <div id="text">                    
                    {values[5].text}
                </div>        
                <div id="image">
                    <img src="5379637510122105179.jpg"/>
                </div>
            </div>
        </div>
    </>
}