export default function ContactLinks(){
    
    const links = [
        // {
        //     name:"",
        //     link:"tel:+237 692 85 05 84",
        //     image:"phone.svg",
        //     size:10
        // },
        // {
        //     name:"",
        //     link:"mailto:admin@lmplatinumestates.cm",
        //     image:"mail.svg",
        //     size:12
        // },
        {
            name:"",
            link:"tel:+237 651 12 50 90",
            image:"phone-calling.svg",
            size:60
        },
        {
            name:"",
            link:"mailto:admin@lmplatinumestates.cm",
            image:"mail-icon.svg",
            size:60
        },
        {
            name:"",
            link:"http://wa.me/237692850584",
            image:"whatsapp-icon.svg",
            size:60
        },
    ];

    
    return(
        <div className="social-links">
            {links.map(l=>
                <a href={l.link} target="_blank">
                <div>
                    <img style={{"width":l.size}} className={"icon-size-" + l.size} src={l.image}/>
                </div>
                </a>
            )}            
        </div>
    )
}
