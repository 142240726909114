import { useState } from "react";
import { BrowserView, isMobile } from "react-device-detect";

export default function OurBusinessArea(props){

    const links = ["Residential","Commercial","Yatching","Cource"];

    const [selectedTabIndex,setSelectedTabIndex] = useState(0);

    return(
        <>
            <div className="vertical-center-element">
                <div className="blue-title-text">
                A propos de nos activités
                    </div>                    
                    <br/>
                <div className="tab-block">
                    {/* <div id="tab_links">
                        {links.map(item=>
                            <div className={selectedTabIndex == links.indexOf(item) ? "selected-tab-text" : "faded-tab-text"} >
                                {item}
                            </div>)
                            }
                    </div> */}
                
                    {!isMobile ? <br/> : ""}
                    <br/>
                    <div id="content">
                        <div id="image">
                            <img src="5379637510122105173.jpg"/>
                        </div>
                        <div id="text">
                        Avez-vous une expérience de longue date dans le secteur immobilier et cherchez-vous 
Pour de nouveaux défis et une perspective de carrière prometteuse ? En tant qu’agent immobilier chez 
 LM Platinium, vous pouvez utiliser vos compétences pour construire une carrière réussie.
<br/>
<br/>
En tant qu’agent immobilier indépendant et mobile, vous bénéficiez de notre expansion mondiale 
Réseau de clients exclusifs et des propriétés correspondantes.
                        <div className="blue-button" style={{width:"230px"}} onClick={props.showContactsClick}>
                            Postulez maintenant
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
