
export const apiurl = "";//process.env.REACT_APP_WEB_API_URL;
export const imagesUrl = apiurl + "api/images/";
export const apartments = "api/apartments";
export const sendcontactinfo = "api/sendcontactinfo";
export const neighborhood = "api/neighborhood";
export const deleteapartments = "api/apartments/delete";
export const imageUpload = "api/uploadimage";
export const imagesListPath = "api/imageslist";
export const deleteimagePath = "api/deleteimage";
export const loginPath = "api/login";
export const checkAuthPath = "api/checkauth";
export const getFiltersRange = "api/getfilterranges";


export const requestBody = (body) => {
    return {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") },
        body: JSON.stringify(body)
    };
}
export const requestHeaderOnly = () => {
    return {
        headers: { 'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem("token") }
    };
}

export const requestHeaderGet = () => {
    return {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
    };
}