import { BrowserView, MobileView, isMobile } from "react-device-detect";
import "../styles/header.scss";
import { useLocation, useNavigate } from "react-router-dom";
import {menuitems} from "../static data/menu-items"
import { useState } from "react";
import * as Query from "../static data/queryparams";
import SocialLinks from "./social-links";
import ContactLinks from "./contact-links";
import ContactUs from "./contact-us";

export default function Header(){
 

    const [showSubMenu, setShowSubMenu] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const [modalOpened,setModalOpened] = useState(false);
    const [selectedService,setSelectedService] = useState("");
    const [currentSubMenu, setCurrentSubMenu] = useState(1);

    const navigate = useNavigate();  
    const location = useLocation();

    function CloseModal(){
        setModalOpened(false);
    }

    function goTo(i){
        if(!!(i.link)) navigate(i.link);
    }
    function goToPage(link, menu, submenu){
       // navigate("/apartments-search?"+Query.property + "=" + link);
        if(submenu == "TOPOGRAPHIE")
        {
            setSelectedService(menu);
            setModalOpened(true);
            return;
        }
        navigate({
            pathname: "/apartments-search",
            search: Query.property + "=" + link
        });
        if(location.pathname=="/apartments-search")navigate(0);
    }

    function menuClick(i){

    }

    return(
        <>
        {modalOpened ? <ContactUs service={selectedService} closeModal = {CloseModal}/> : ""}
        <div className="header">
            <div className="top-header">
                <div id="imglogo" 
                onClick={()=>navigate("/")}
                >
                    <img src="logo.jpg"/>
                </div>
                <div id="logo" 
                onClick={()=>navigate("/")}
                >
                    <img src="2.1.png"/>
                    <div id="slogan">Vivez l’immobilier autrement</div>
                </div>
                                                             
                        <div id="social-links">
                            <BrowserView>
                                <ContactLinks/>
                            </BrowserView>
                        </div>
                    <div id="hd-contact-info">
                        <BrowserView>                        
                            {/* <div>
                                Email : <a href="mailto:admin@lmplatinumestates.cm">admin@lmplatinumestates.cm</a><br/>
                                Calls/WhatsApp : <a href="tel:+237 692 85 05 84">+237 692 85 05 84</a><br/>
                                Calls : <a href="tel:+237 651 12 50 90">+237 651 12 50 90</a> / <a href="tel:+237 658 91 74 77">+237 658 91 74 77</a><br/>
                            </div>     */}
                        </BrowserView>
                    </div>
                    {isMobile ? 
                        <div id="burger" onClick={()=> setShowMenu(!showMenu)}>
                            <img src="burger.svg"/>
                        </div>
                        :
                        ""
                    }
            </div>
            {/* <BrowserView> */}
                <div style={{display:isMobile && !showMenu? "none" : ""}} 
                className="menu-header" 
                onMouseLeave={
                    ()=>{
                        setShowSubMenu(false);
                        if(isMobile) setShowMenu(false);
                    }
                }>
                    {menuitems.map((item,i)=>
                        <div>
                            <div id="menu-item" onMouseOver={()=>
                                {
                                setCurrentSubMenu(i);
                                setShowSubMenu(true);
                                }
                                } onClick={()=>goTo(item)}>
                                {item.name}                           
                            </div>
                            
                        </div>
                        )
                        }

                        <div style={{display:showSubMenu && menuitems[currentSubMenu].descr!="" ? "" : "none"}}
                         className={"submenu"}                                
                        >
                            {menuitems[currentSubMenu].submenu.length == 0 && !isMobile ?
                                ""
                                :
                                <div key={menuitems[currentSubMenu].name + "s"} id = "submenuitems">
                                    {menuitems[currentSubMenu].submenu.map(x=>
                                        <>
                                            <div onClick={()=>goToPage(x.link, x.name, menuitems[currentSubMenu].name)}>
                                                {x.name}
                                            </div>
                                        </>
                                    )}
                                </div>     
                            }
                            
                            <div key={menuitems[currentSubMenu].name}
                                className={menuitems[currentSubMenu].submenu.length == 0 && !isMobile ?
                                    "menu-centered-description"
                                    :
                                    ""
                                }
                                id="menudescription">
                                {menuitems[currentSubMenu].descr}
                            </div>
                        </div>
                </div>
            {/* </BrowserView> */}
            <MobileView>
                <div>

                </div>
            </MobileView>
        </div>
        </>
    )
}