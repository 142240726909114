import ApartsBlock from "../components/blocks/aparts-block";
import FindMyShop from "../components/blocks/find-my-shop";
import FindNow from "../components/blocks/find-now";
import OurBusinessArea from "../components/blocks/our-business-area";
import Subscribe from "../components/blocks/subscribe";
import ThreeReasons from "../components/blocks/three-reasons";
import Top3 from "../components/blocks/top3-values";
import Header from "../components/header";
import LeftMenu from "../components/left-menu";
import MainPageContentBlock from "../components/main-page-content-block";
import Slideshow from "../components/slideshow";
import "../styles/main.scss";
import "../styles/apart.scss";
import { useEffect, useState } from "react";
import { MobileView } from "react-device-detect";
import SocialLinks from "../components/social-links";
import ContactsCard from "../components/contacts-card";
import Footer from "../components/footer";
import OurServices from "../components/our-services";
import { useLocation } from "react-router-dom";

export default function Main(){
    const [showContacts, setshowcontacts] = useState(false);

    const { hash } = useLocation();
    function showContactsClick(){
        setshowcontacts(true);
    }
    function hideContactsClick(){
        setshowcontacts(false);
    }    
    useEffect(() => {   
        //preload();     
        if(hash=="#services")
            setTimeout(() => {                
                goToAnchor('our-services');
            }, 1000); 
    }, []);

    function goToAnchor(anchor){
        const posy = document.getElementById(anchor)?.getBoundingClientRect().top;
        document.getElementById(anchor)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });   
        const yOffset = -160; 
        const y = posy + window.scrollY + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }

    return (
        <>
            <Header/>        
            <div id="page">
                <div className="left-menu">
                    <LeftMenu/>
                </div>
                <div className="main-page-content">      
                    <Slideshow/>
                    <MainPageContentBlock id="aparts" content={<ApartsBlock/>}/>
                    {/* <MainPageContentBlock id="properties" content={<Top3/>}/> */}
                    <OurServices/>
                    <MainPageContentBlock  content={<FindNow showContactsClick={showContactsClick}/>}/>
                    <MainPageContentBlock id="why" content={<ThreeReasons/>}/>
                    <MainPageContentBlock id="fmshop" content={<FindMyShop showContactsClick={showContactsClick}/>}/>
                    <MainPageContentBlock id="aboutus" content={<OurBusinessArea showContactsClick={showContactsClick}/>}/>
                    {/* <MainPageContentBlock id="subscribe" content={<Subscribe/>}/> */}
                 </div> 

                 
            </div>
            {showContacts ? <div className="contacts-modal">
                    <ContactsCard closeModal={hideContactsClick}/>
                 </div>
                 :
                 ""}  

        </>
    )
}