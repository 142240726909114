export default function FindNow(props){

    return(
        <>
            <img id="find-now-image" src="home.jpg"/>
            <div className="vertical-center-element">
                <div className="white-title-text">
                    Faire estimer son bien immobilier?
                    </div>
                <div className="white-text">
                Vous envisagez de vendre votre propriété ou d’obtenir une évaluation gratuite et non contraignante 
sur la valeur marchande actuelle de votre propriété?
                    </div>
                    <br/>
                    <br/>
                    <div className="blue-button" onClick={props.showContactsClick}>
                        Trouvez maintenant
                    </div>
            </div>
        </>
    )
}
